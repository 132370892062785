import {
  Collapse, Progress, Tooltip,
} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '@/store/root-store';
import {snakeToCamel, createArrayOfNumbersAsString} from '@/utils/string';
import {ChevronDown, ChevronUp} from 'react-feather';
import {getSingleUrlParam} from '@/utils/url';
import {getPercentage} from '@/utils/number';
import {StyledTechnicalFixesCollapse, TechnicalFixesWrapper, TasksCard, TaskContent, MessagesCount} from '../style';
import * as proSolid from '@fortawesome/pro-solid-svg-icons';
import * as proDuotone from '@fortawesome/pro-duotone-svg-icons';
import {ComingSoonBanner} from './comingSoonBanner';
import {useRouter} from 'next/router';
import {IssueCategoriesIcons, getIssueCategoriesTooltipText, IssuesSubGroup, publicPageCategories} from '../Constants';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {LargeBadge} from '@/components/common-components/components/badge';
import {getOttoTablePageSize} from '@/api/common-utils';
import {faLocationExclamation} from '@fortawesome/pro-regular-svg-icons';
// const iconMapping = {
//   solid: proSolid,
//   duotone: proDuotone,
// };
interface Props {
  activeTab: string;
  showSearch: number;
  setShowSearch: (e) => void;
  scrollToSection: () => void;
  setShowComingSoon: (value: boolean) => void;
  showComingSoon: boolean;
  comingSoonCount?: number;
  campaignsId?: string;
  setCampaignsId?: (value: string) => void;
}

const categoryIconStyles = {
  'ai_landing_page_builder': {width: 15},
};

export const IssueCategoriesCollapse = observer(({activeTab, setShowSearch, scrollToSection, setShowComingSoon, showComingSoon, comingSoonCount, campaignsId, setCampaignsId}: Props) => {
  const {ottoV2Store: {
    setActiveKeys,
    setStatusKeyValue,
    getStatusKeyValue,
    loadIssueTableData,
    getOttoV2Project,
    restOttoUrls,
    setSelectedCategory,
    setLoadingDetail,
    deployingOttoUrls,
    setDeployingOttoUrls,
    setAIGenerateBtnClick,
    aIGenerateState,
    setSelectedIssue,
    issueTypeArray,
    setIssueTypeArray,
    getOttoUrls,
    setIsDeploying,
    isDeploying,
    issueTypeSelected,
    setIssueTypeSelected,
    setTopicalMapsShouldRepoll,
    setOttoSearchTerm,
    setSearchText,
    setDeployRollBackAll,
    deployRollBackAll,
    loadingProject,
    selectedCategory,
    setAiShouldRepoll,
    selectedIssue,
    selectedReviewFilters,
    setNlpPagination,
    setNavigatedFromGbpOptimizations,
  },
  campaigns: {getCampaigns, getFolders, getGlobalBlockList},
  } = useStore('');
  const {settings: {customer: {profile: {whitelabelOtto, isLinkgraph}}}} = useStore('');
  const {inbox: {getUnreadEmailCount, emailUnreadCount}} = useStore('');
  const isPublicPage = !!getSingleUrlParam('public_hash');

  const [issueTypesFrontendMappingV2, setIssueTypesFrontendMappingV2] = useState([]);
  const [issueTypes, setIssueTypes] = useState([]);
  const router = useRouter();
  const subGroup = getSingleUrlParam('subGroup');
  const tooltipTextWithWhitelabel = getIssueCategoriesTooltipText(whitelabelOtto);

  useEffect(() => {
    if (getOttoV2Project?.issueTypesFrontendMappingV2 && getOttoV2Project?.issueTypesFrontendMappingV2 !== undefined) {
      /* If any issue category doesn't have subGroup array, you can add subGroup through this array */
      /* If you change something in this logic make you don't crash your production development */
      let clone = [...getOttoV2Project.issueTypesFrontendMappingV2.map(item => {
        if (!getOttoV2Project.isInstantIndexingAllowed || !isLinkgraph) {
          return {
            ...item,
            subGroups: item.subGroups?.filter(subGroup => subGroup.group !== 'gsc_instant_indexing') || [],
          };
        }
        return item;
      }).map(i => {
        if (i?.group === 'indexing') {
          const subGroups = i.subGroups.map(value => {
            if (value?.group === 'gsc_instant_indexing') {
              return {...value, subGroups: [
                {
                  group: 'sitemap_indexing',
                  label: 'Sitemap Indexing',
                  requiredIntegration: null,
                  isComingSoon: false,
                  isSitewide: false,
                },
                {
                  group: 'custom_url_based_indexing',
                  label: 'Custom URL Based Indexing',
                  requiredIntegration: null,
                  isComingSoon: false,
                  isSitewide: false,
                }]};
            }
            return value;
          });
          return {...i, subGroups: subGroups};
        }
        return i;
      })] as any;
      if (isPublicPage) {
        clone = clone?.filter(item => publicPageCategories.includes(item?.group));
      }

      if (clone?.length) {
        for (let index = 0; index < clone?.length; index++) {
          if (IssuesSubGroup[clone[index]?.group]) {
            for (let j = 0; j < clone[index]?.subGroups.length; j++) {
              for (let k = 0; k < IssuesSubGroup[clone[index]?.group].length; k++) {
                if (IssuesSubGroup[clone[index]?.group][k][clone[index].subGroups[j].group]) {
                  if (!clone[index].subGroups[j].isComingSoon || ['campaigns', 'inbox', 'templates', 'gsc_instant_indexing', 'profile_optimizations'].includes(clone[index].subGroups[j].group)) {
                    if (clone[index].subGroups[j].group == 'profile_optimizations') {
                      clone[index].subGroups[j].isComingSoon = false;
                    }
                    clone[index].subGroups[j].subGroups = [
                      {
                        label: IssuesSubGroup[clone[index].group][k][clone[index].subGroups[j].group].label,
                        group: IssuesSubGroup[clone[index].group][k][clone[index].subGroups[j].group].group,
                        isComingSoon: false,
                        requiredIntegration: clone[index].subGroups[j].requiredIntegration,
                      },
                    ];
                  }
                }
              }
            }
          }
        }
      }
      const alphaModules = clone?.map(item => {
        if (['gbp_audit', 'digital_outreach', 'indexing'].includes(item?.group)) {
          const updatedItem = {...item, isComingSoon: false};
          const updatedSubGroups = updatedItem?.subGroups?.map(subGroup => {
            if (['campaigns', 'inbox', 'templates', 'gsc_instant_indexing', 'profile_optimizations'].includes(subGroup?.group)) {
              return {...subGroup, isComingSoon: false};
            }
            return subGroup;
          });
          return {...updatedItem, subGroups: updatedSubGroups};
        }
        return item;
      });
      setIssueTypesFrontendMappingV2(isLinkgraph ? alphaModules : clone);
    }
  }, [getOttoV2Project?.issueTypesFrontendMappingV2]);

  useEffect(() => {
    triggerIssueTable();
  }, [issueTypes]);

  const categories = selectedIssue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length)?.group : selectedIssue;
  const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === categories || issueObj.label === categories)?.subGroups?.find(categoryObj => categoryObj?.group === selectedCategory);

  const triggerIssueTable = async () => {
    if (issueTypes?.length) {
      const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === issueTypes[0]);
      const savedPageSize = getOttoTablePageSize(issueTypes[0]) || 10;
      const data = await loadTableData({issueType: issueTypes[0], isSitewide: itemObject?.isSitewide, page: 1, pageSize: savedPageSize});
      if (data !== undefined) {
        const array = [...issueTypes];
        array.shift();
        setIssueTypes(array);
      }
    } else {
      setDeployRollBackAll(false);
      setLoadingDetail(false);
    }
  };

  const [activeTechnicalKeys, setActiveTechnicalKeys] = useState(['1']);
  const groups = getOttoV2Project?.issuesCountBreakdown['groups'] || {};
  const [selectedField, setSelectedField] = useState(null);
  const [mainCategory, setMainCategory] = useState(null);
  const uuid = getSingleUrlParam('uuid');

  useEffect(()=> {
    setSelectedField('');
  }, [activeTab]);
  // This useEffect is responsible for selecting and the fetching data for the first group of first category in any of the tab
  useEffect(() => {
    if (!isDeploying && issueTypesFrontendMappingV2 && issueTypesFrontendMappingV2?.length > 0) {
      let activeIssue; let firstCategory; let firstIssueTypeArray; let firstField; let groupOfParam;
      if (router.query?.subGroup && !selectedField) {
        groupOfParam = issueTypesFrontendMappingV2.filter(issue => issue?.subGroups.filter(item=> item.group=== router.query?.subGroup)?.length)[0]?.group;
        firstCategory = router.query?.category;
        firstField = router.query?.subGroup;
        firstIssueTypeArray = issueTypesFrontendMappingV2?.find(item=> item?.group == groupOfParam)?.subGroups?.find(item=> item?.group == router?.query?.subGroup)?.subGroups?.map((subGroup: any) => subGroup?.group);
        // router.replace({
        //   query: {...router.query, subGroup: ''},
        // });
      } else {
        activeIssue = issueTypesFrontendMappingV2.find(issue => issue?.group === activeTab)?.subGroups?.find(group => (!checkDisableSection(group) && group?.subGroups?.length));

        firstCategory = activeTab === 'All' ? issueTypesFrontendMappingV2[0]?.group : issueTypesFrontendMappingV2.find(issue => issue?.group === activeTab)?.group;
        firstField = activeTab === 'All' ? issueTypesFrontendMappingV2[0]?.subGroups[0]?.group:
          activeIssue?.group;
        firstIssueTypeArray = activeTab === 'All' ?
          issueTypesFrontendMappingV2[0]?.subGroups[0]?.subGroups?.map((subGroup: any) => subGroup?.group):
          activeIssue?.subGroups?.map((subGroup: any) => subGroup?.group);
      }
      const activeKeysList = firstIssueTypeArray?.map(item => ({key: 'all', issue: item}));
      setActiveKeys && setActiveKeys(activeKeysList);
      if (firstCategory && firstField) {
        setMainCategory(firstCategory);
        if (showComingSoon) {
          setSelectedField('');
        } else {
          setSelectedField(firstField);
        }
        setIssueTypeArray(firstIssueTypeArray);
        restOttoUrls();
        loadIssueTables(
          firstIssueTypeArray,
          firstField, groupOfParam || firstCategory, 10, 1,
        );
      } else {
        setMainCategory('');
        setSelectedField('');
        setIssueTypeArray([]);
        setSelectedIssue('');
        setSelectedCategory('');
        restOttoUrls();
      }
    } else {
      setIsDeploying(false);
    }
  }, [issueTypesFrontendMappingV2, activeTab]);

  // This useEffect is responsible to open/close table dropdowns by default
  useEffect(() => {
    if (issueTypesFrontendMappingV2?.length) {
      const keys = createArrayOfNumbersAsString(issueTypesFrontendMappingV2?.length);
      setActiveTechnicalKeys(keys);
    }
  }, [issueTypesFrontendMappingV2]);
  const reloadData = async () => {
    const savedPageSizeReloadData = getOttoTablePageSize(issueTypes[0]);
    if (deployingOttoUrls && issueTypesFrontendMappingV2 && issueTypesFrontendMappingV2.length > 0) {
      const firstGroup = issueTypesFrontendMappingV2.find(issueType=>issueType.group === mainCategory);
      const firstSubGroup = firstGroup?.subGroups.find((subGroup: any)=>subGroup?.group === selectedField);
      const reloadingObj = getOttoUrls.find(obj => issueTypeArray.includes(obj.issueType));
      const filter = getOttoUrls.find(d => d.issueType === issueTypeSelected);
      if (deployRollBackAll) {
        const activeKeysList = (issueTypeSelected ? [issueTypeSelected] : firstSubGroup?.subGroups?.map((subGroup: any)=>subGroup?.group))?.map(item => ({key: 'all', issue: item}));
        setActiveKeys(activeKeysList);
      }
      await loadIssueTables(issueTypeSelected ? [issueTypeSelected] : firstSubGroup?.subGroups?.map((subGroup: any)=>subGroup?.group), selectedField, mainCategory, savedPageSizeReloadData, issueTypeSelected ? filter?.page : reloadingObj?.page ?? 1, true);
      setAIGenerateBtnClick(false);
      setDeployingOttoUrls(false);
      setIssueTypeSelected('');
    }
  };

  // This useEffect is responsible for fetching data of same category on pagination/deploy/roll back
  useEffect(() => {
    reloadData();
  }, [deployingOttoUrls]);

  // This method check the category/section is disabled/enabled
  const checkDisableSection = (section: any) => {
    let requiredIntegration = false;
    if (section?.requiredIntegration) {
      if ((section?.requiredIntegration === 'gsc' && !getOttoV2Project?.connectedData?.isGscConnected) || (section?.requiredIntegration === 'gbp' && !getOttoV2Project?.connectedData?.isGbpConnected)) {
        requiredIntegration = true;
      }
    }
    const isDisabled = (section?.isComingSoon || requiredIntegration) ?? false;
    return isDisabled;
  };

  const loadTableData = async ({issueType, isSitewide, page, pageSize}: {issueType: string; isSitewide?: boolean; page?: number; pageSize?: number}) => {
    const reloadingObj = getOttoUrls.find(obj => obj.issueType == issueType);
    if (issueTypeArray.includes(issueType)) {
      const params = {
        uuid,
        otto_project: getOttoV2Project?.id,
        issue_type: issueType,
        page_size: deployRollBackAll ? 10 : (reloadingObj?.pageSize || pageSize),
        page: deployRollBackAll ? 1 : (reloadingObj?.page || page),
        is_loading: false,
        deploy_status: getStatusKeyValue() ? getStatusKeyValue() : 'all',
      };
      if (issueType == 'topical_maps_and_supplemental_content') {
        setTopicalMapsShouldRepoll(true);
      } else {
        setTopicalMapsShouldRepoll(false);
      }
      if (issueType !== 'dynamic_indexing' && issueType !== 'ai_landing_page_builder') setOttoSearchTerm('');
      // isSitewide is related to alt image text
      if (params?.issue_type == 'unanswered_reviews') {
        if (selectedReviewFilters?.star_rating__in) {
          params['star_rating__in'] = selectedReviewFilters?.star_rating__in;
        }
        if (selectedReviewFilters?.is_replied) {
          params['is_replied'] = selectedReviewFilters?.is_replied;
        }
        if (selectedReviewFilters?.reply__status__in) {
          params['reply__status__in'] = selectedReviewFilters?.reply__status__in;
        }
      }
      const data = await loadIssueTableData(params, isSitewide, aIGenerateState);
      return data;
    }
  };

  // This method is responsible for fetching data each subGroup in a group
  const loadIssueTables = async (issueArray: string[], category: string, issue: string, pageSize: number, page: number, stopLoading?: boolean) => {
    if (issue == 'digital_outreach') {
      getUnreadEmailCount();
    }
    setSelectedIssue(issue);
    setSelectedCategory(category);

    if (category === 'campaigns') {
      if (campaignsId) {
        setCampaignsId('');
      } else {
        getCampaigns(false, null, getOttoV2Project?.id);
        getFolders();
        getGlobalBlockList();
      }
    }

    if (issue !== 'digital_outreach') {
      if (!stopLoading) {
        setLoadingDetail(true);
      }
      setIssueTypes(issueArray);
    }
  };

  const handleTechnicalIssues = value => {
    setActiveTechnicalKeys(value as string[]);
  };

  /* Getting category data on category click and passing to the API */
  const handleClickOnCategory = (technicalFix, requiredIntegration, issue) => {
    const mappingArray = technicalFix?.subGroups?.map((subGroup: any) => subGroup?.group) || [];
    const gbpAuditArray = ['gbp_posts', 'qanda_suggestions'];
    const activeKeysList = mappingArray?.map(item => ({key: 'all', issue: item}));
    setActiveKeys(activeKeysList);
    setStatusKeyValue('all');
    setNlpPagination(1, 10);
    if ((!technicalFix.isComingSoon && !requiredIntegration) || gbpAuditArray.includes(technicalFix?.group)) {
      // eslint-disable-next-line
      setShowSearch(-1);
      setMainCategory(issue?.group);
      setIssueTypeArray(mappingArray);
      setSelectedField(technicalFix?.group);
      restOttoUrls();
      loadIssueTables(mappingArray, technicalFix?.group, issue?.group, 10, 1);
    }
  };

  const renderIcon = tf => {
    // const [fontAwsomeIconType, fontAwsomeIconName] = createDynamicFontAwsomeIcons(tf?.icon);
    const group = groups[snakeToCamel(tf?.group)];
    if (tf?.isComingSoon || !group || group?.total === 0) {
      return tf.group == 'profile_optimizations' ? <FontAwesomeIcon icon={faLocationExclamation} color='#936BDA' height={15} width={20} style={{marginTop: '3px', ...(categoryIconStyles[tf?.group] || {})}}/> :
        <img className='task-icon' src={IssueCategoriesIcons[tf.group]} height={15} width={20} style={{marginTop: '3px', ...(categoryIconStyles[tf?.group] || {})}}/>;
      // return <FontAwesomeIcon icon={iconMapping[fontAwsomeIconType][fontAwsomeIconName] || proDuotone.faCheckCircle} style={{marginTop: 2, minWidth: 14}} />;
    } else if (group?.approved === group?.total) {
      return <FontAwesomeIcon icon={proDuotone.faCheckCircle} color='#2AC155' fontSize={18} />;
    } else {
      return (
        <>{
          groups[snakeToCamel(tf?.group)] &&
          <Progress
            type='circle'
            trailColor='rgb(229 229 229)'
            percent={getPercentage(groups[snakeToCamel(tf?.group)]?.approved ?? 0, 0, groups[snakeToCamel(tf?.group)]?.total ?? 0)}
            strokeColor='#2D6CCA'
            width={16}
            strokeWidth={14}
            gapDegree={0}
            size='small'
            showInfo={false}
          />
        }</>
      );
    }
  };

  useEffect(()=> {
    if (subGroup) {
      scrollToSection();
    }
  }, [router.query]);


  useEffect(() => {
    const issue = issueTypesFrontendMappingV2?.find(group =>
      group.subGroups?.some(subgroup => subgroup.group === selectedCategory));
    const technicalFix = issue?.subGroups?.find(fix => fix.group === selectedCategory);
    const requiredIntegration = technicalFix?.requiredIntegration === 'gbp' && !getOttoV2Project?.connectedData.isGbpConnected;
    if (technicalFix && issue) {
      router.replace({
        query: {...router.query, subGroup: technicalFix?.group},
      });
      setSearchText('');
      setShowComingSoon(false);
      checkDisableSection(technicalFix) ? {} : handleClickOnCategory(technicalFix, requiredIntegration, issue);
      scrollToSection();
    }
  }, [selectedCategory]);

  return (
    <div>
      <StyledTechnicalFixesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={['1']}
        activeKey={activeTechnicalKeys}
        onChange={key => handleTechnicalIssues(key)}
        expandIcon={({isActive}) => isActive ? <ChevronUp/>:<ChevronDown/>}
      >
        {issueTypesFrontendMappingV2 && issueTypesFrontendMappingV2.filter(issue => !issue?.isComingSoon).filter(issue => activeTab === 'All' ? true : issue?.group === activeTab).map((issue, index) => (
          <Collapse.Panel header={<><span style={{maxWidth: '88%'}}>{issue.label}</span></>} key={index + 1}>
            <TechnicalFixesWrapper>
              <div style={{paddingRight: '20px'}}>
                {issue?.subGroups.length && issue?.subGroups.filter(issue => !issue?.isComingSoon).map(technicalFix => {
                  const integrationMapping = {
                    gbp: 'Google Business Profile Required',
                    gsc: 'Google Search Console Required',
                  };
                  let requiredIntegration = false;
                  if (technicalFix?.requiredIntegration) {
                    if ((technicalFix?.requiredIntegration === 'gsc' && !getOttoV2Project?.connectedData?.isGscConnected) || (technicalFix?.requiredIntegration === 'gbp'&& !getOttoV2Project?.connectedData.isGbpConnected)) {
                      requiredIntegration = true;
                    }
                  }
                  return (
                    <Tooltip title={requiredIntegration ?
                      integrationMapping[technicalFix?.requiredIntegration] ? integrationMapping[technicalFix?.requiredIntegration] : `${technicalFix?.requiredIntegration} required` :
                      technicalFix?.isComingSoon ? 'Coming soon' : ''} key={technicalFix?.group}>
                      <Tooltip title={tooltipTextWithWhitelabel[technicalFix?.group]} placement='right' overlayInnerStyle={{backgroundColor: '#000', borderRadius: '5px'}}>
                        <TasksCard
                          key={technicalFix?.group}
                          selected={selectedField === technicalFix?.group}
                          disabled={checkDisableSection(technicalFix)}
                          onClick={() => {
                            setAiShouldRepoll(false);
                            router.replace({
                              query: {...router.query, subGroup: technicalFix?.group},
                            });
                            setSearchText('');
                            setShowComingSoon(false);
                            checkDisableSection(technicalFix) ? {} : handleClickOnCategory(technicalFix, requiredIntegration, issue);
                            scrollToSection();
                            setNavigatedFromGbpOptimizations(false);
                          }}
                        >
                          <TaskContent disabled={technicalFix?.isComingSoon ?? false}>
                            <div className='title'>
                              <div style={{display: 'flex', gap: '10px'}}>
                                {renderIcon(technicalFix)}
                                <div>{technicalFix?.group == 'qanda_suggestions' ? 'Question & Answer Suggestions' : technicalFix?.group == 'profile_optimizations' ? 'GBP Optimization' : technicalFix?.label}</div>
                                {technicalFix?.group == 'inbox' && emailUnreadCount > 0 && <MessagesCount>{emailUnreadCount}</MessagesCount>}
                                {requiredIntegration && (
                                  <>
                                    {technicalFix?.requiredIntegration === 'gbp' ?
                                      <FontAwesomeIcon className='task-icon' icon={proSolid.faStore} fontSize='16px' style={{opacity: '50%', marginTop: '2px'}}/> :
                                      <img className='task-icon' src='/img/icon/GSC_Icon.png' height={15} width={20} style={{opacity: '50%', marginTop: '3px'}}/>}
                                  </>
                                )}
                                {isLinkgraph && ['campaigns', 'inbox', 'templates', 'gsc_instant_indexing'].includes(technicalFix?.group) && <LargeBadge alpha/>}
                                {['press_release_distribution', 'aggregator_network', 'ai_landing_page_builder', 'profile_optimizations'].includes(technicalFix?.group) && <LargeBadge beta/>}
                              </div>
                            </div>
                            {loadingProject ? <SkeletonHorizontalLoaderGray height={15} width={50} /> :
                              groups[snakeToCamel(technicalFix?.group)] && groups[snakeToCamel(technicalFix?.group)]?.total === 0 ?
                                <div className='value'>No issues found</div> :
                                <>
                                  {
                                    groups[snakeToCamel(technicalFix?.group)] ?
                                      <div className = 'value'>{ groups[snakeToCamel(technicalFix?.group)]?.approved ?? '' } of {groups[snakeToCamel(technicalFix?.group)]?.total ?? ''}</div> :
                                      <div className = 'value'></div>
                                  }
                                </>
                            }
                          </TaskContent>
                        </TasksCard>
                      </Tooltip>
                    </Tooltip>
                  );
                })}
              </div>
            </TechnicalFixesWrapper>
          </Collapse.Panel>
        ))}
      </StyledTechnicalFixesCollapse>
      {comingSoonCount > 0 && <ComingSoonBanner setShowComingSoon={setShowComingSoon} setSelectedField={setSelectedField} comingSoonCount={comingSoonCount} scrollToSection={scrollToSection}/>}
    </div>
  );
});
